import { useState, useEffect } from "react";

const WhatsAppButton = ({ phoneNumber, message }) => {

  const [isVisible, setIsVisible] = useState(true);


  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      // Hide when user is at the bottom
      setIsVisible(scrollY + windowHeight < documentHeight - 10);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const encodedMessage = encodeURIComponent(message);
  const whatsappLink   = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  if (!isVisible) return null; // Hide when at the bottom

  return (
    <a
      href={whatsappLink}
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-24 left-8 bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600 transition duration-300 flex items-center justify-center"
    >
      <img align="center" style={{height:"36px",padding:"0px 0px",display:"inline"}} src="assets/whatsapp-icon-white.png" />
    </a>
  );
};

export default WhatsAppButton;