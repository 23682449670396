import React, { useState, useEffect, useRef } from 'react';

import InputMask from 'react-input-mask';

import ModalMessage    from './ModalMessage';
import Loading         from './Loading';
import EventHeader     from './EventHeader'
import InactivityTimer from './components/InactivityTimer';

import './App.css';

import * as utils from './Utils';

window.addEventListener( "pageshow", function ( event ) {
  var historyTraversal = event.persisted || ( typeof window.performance != "undefined" &&  window.performance.navigation.type === 2 );
  if ( historyTraversal ) { window.location.reload(); } });

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class QRCodeComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      imageUrl: ''
    };
  }

  componentDidMount() {

    const url = process.env.REACT_APP_API_ENDPOINT + '/cart/' + this.props.uuid + '/pay/pix?token=' + this.props.token + "&mode=0" + (this.props.numInstallments > 1 ? '&installments=' + this.props.numInstallments : '');
    fetch(url, {
      method: 'GET',
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }}
      ).then(response => {

        if (!response.ok)
          throw new Error('Failed to fetch QR code');

        return response.blob();
      })
      .then(imageBlob => {

        const imageUrl = URL.createObjectURL(imageBlob);
        this.setState({ imageUrl });
      })
      .catch(error => {

        console.error('Error fetching QR code:', error);
      }
    );
  }

  componentWillUnmount() {

    URL.revokeObjectURL(this.state.imageUrl);
  }  

  render() {
    const { imageUrl } = this.state;
    return (
      <div>
        {imageUrl && <img src={imageUrl} alt="QR Code" className="max-w-full h-auto" style={{ width: '200px' }} />}
      </div>
    );
  }
}

class CopyToClipboardButton extends React.Component {

  constructor(props) {

    super(props);

    this.state = {

      pasteInfo: '',
      isContentEmpty: true,
      showNotification: false
    };
  }

  copyToClipboard = () => {

    const content = this.state.pasteInfo;
    navigator.clipboard.writeText(content)
      .then(() => {

        console.log('Content copied to clipboard:', content);
    
        if (this.state.showNotification == false) {

          this.setState({ showNotification : true });

          setTimeout(() => {
            this.setState({ showNotification : false });
          }, 2000);
        }
      })
      .catch(error => {

        console.error('Error copying to clipboard:', error);
      }
    );
  };

  componentDidMount = async() => {

    await sleep(1000);

    const url = process.env.REACT_APP_API_ENDPOINT + '/cart/' + this.props.uuid + '/pay/pix?token=' + this.props.token + "&mode=1" + (this.props.numInstallments > 1 ? '&installments=' + this.props.numInstallments : '');
    fetch(url, {
      method: 'GET',
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }}
      ).then(response => {

        if (!response.ok)
          throw new Error('Failed to fetch paste info');

        return response.text();
      })
      .then(pasteInfo => {

        this.setState({ pasteInfo });

        console.log('Paste info:', pasteInfo);

        const isContentEmpty = !pasteInfo || pasteInfo.trim() === '';
        this.setState({ isContentEmpty });
      })
      .catch(error => {

        console.error('Error fetching paste info:', error);
      }
    );
  }

  render() {

    return (
      <div className="relative">
        <button className={" text-white text-1xl font-normal px-4 py-2 rounded " + (!this.state.isContentEmpty ? 'bg-orange-400' : 'bg-gray-400 cursor-not-allowed')}
          onClick={this.copyToClipboard} disabled={this.state.isContentEmpty} style={{ minWidth: '150px' }}>
          Ou clique aqui para copiar o código e colar no app do seu banco
        </button>
        {this.state.showNotification && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 pointer-events-none">
            <div className="bg-white text-black text-center px-4 py-2 rounded shadow-lg">
              Código Pix copiado.<br/>Cole no app do seu banco.
            </div>
          </div>
        )}      
      </div>
    );
  }
}

const fetchCart = async (uuid, numInstallments) => {

  try {

    const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/cart/' + uuid + (numInstallments > 1 ? '?installments=' + numInstallments : ''), {
      method: 'GET',
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    });

    if (!response.ok)
      throw new Error(response.message);

    const data = await response.json();
    return data;
  }
  catch (error) {

    console.error('Error fetching items: ', error);
    throw error;
  }
};

function App() {

  const [loading,        setLoading         ] = useState(true);
  const [itemCategories, setItemCategories  ] = useState({});
  const [modalMessage,   setModalMessage    ] = useState(null);
  const [modalNextFocus, setModalNextFocus  ] = useState(null);
  const [modalCallback,  setModalCalback    ] = useState(null);
  const [paymentDone,    setPaymentDone     ] = useState(false);
  const [cartIsFree,     setCartIsFree      ] = useState(false);
  const [timeLeft,       setTimeLeft        ] = useState(null);

  const eventUUID       = localStorage.getItem('eventUUID');
  const cartUUID        = localStorage.getItem('cartUUID' );
  const mdlToken        = localStorage.getItem('mdlToken' );
  const numInstallments = localStorage.getItem('mdlCartInstallments');

  var pageError = false;

  useEffect(() => {

    if (!cartUUID || cartUUID.length == 0) {

      console.log("Error: cart not defined.");
      window.location.href = process.env.REACT_APP_LANDING_DEFAULT;
      return;
    }

    const storedExpiration = localStorage.getItem("cartExpirationTimestamp");
    if (storedExpiration) {

      const expirationTime = parseInt(storedExpiration, 10);
      const remaining = Math.max(0, expirationTime - Date.now());
      setTimeLeft(remaining);      

      const interval = setInterval(() => {
        setTimeLeft((prev) => {

          if (prev == null)
            return;

          var aux = Math.max(0, expirationTime - Date.now());
          if (aux == 0) {

            clearInterval(interval);
            localStorage.removeItem("cartExpirationTimestamp");

            setLoading(true);
            setModalMessage("Esta sessão expirou. Por favor, tente novamente.");
            setModalCalback(() => {
              return () => {
                window.location.href = '/' + localStorage.getItem('landingTag');
              };        
            });
          }

          return aux;
        });
      }, 1000);     
    }

    console.log('numInstallments: ' + numInstallments);

    const loadPurchasedItems = async () => {

      try {

        //console.log('cartUUID='+cartUUID);

        const cart = await fetchCart(cartUUID, numInstallments);
        if (cart != null && cart.items != null && cart.items.length > 0) {

          setItemCategories(cart);
          setLoading(false);

          setTimeout(() => { CheckPurchaseDone(); }, 1000);    
        }
        else {

          throw new Error('Could not retrieve items information');
        }
      }
      catch (error) {

        console.log("Error: " + error.message);

        setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
        setModalCalback(() => {
          return () => {
            window.location.reload();
          };        
        });
      }
    };

    loadPurchasedItems();

  }, []);

  const CheckPurchaseDone = async () => {

    console.log("Checking for purchase done...");

    const url = process.env.REACT_APP_API_ENDPOINT + '/cart/' + cartUUID + '/pay/pix?token=' + mdlToken + "&mode=2" + (numInstallments > 1 ? '&installments=' + numInstallments : '');
    try {

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Pragma: 'no-cache',
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });

      if (!response.ok) {

        if (response.status == 409) { //out of items or session has expired:

          const result = await response.json();
          if (result && result.msg)
            console.error('Error fetching purchase status: ', result.msg);

          setLoading(true);

          setModalMessage(result.msg ? result.msg : "Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
          setModalCalback(() => {
            return () => {
              window.location.href = '/' + localStorage.getItem('landingTag');
            };        
          });

          pageError = true;
        }
        else
          throw new Error(response.message);
      }
      else {

        const data = await response.json();
        if (data.paymentStatus == 1) {

          setPaymentDone(true);
          setCartIsFree (data.cartIsFree > 0);
          setTimeLeft(null);

          localStorage.setItem('mdlCartPaid', cartUUID);

          clickSound.play().catch((error) => {
            console.error('Error playing sound:', error);
          });

          setModalMessage(data.cartIsFree > 0 ? "Isento de pagamento!" : "Pagamento efetuado!");
          setModalCalback(() => {
            return () => {
              window.location.href = '/participants';
            };
          });        
        }
        else if (!pageError) {

          setTimeout(() => { CheckPurchaseDone(); }, 1000);    
        }
      }
    }
    catch (error) {

      console.error('Error fetching purchase status: ', error);

      setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
      setModalCalback(() => {
        return () => {
          window.location.reload();
        };        
      });
    }
  }
 
  const handleModalMessageClose = () => {

    setModalMessage(null);

    if (modalCallback) {

      modalCallback();
      setModalCalback(null);
    }

    if (modalNextFocus) {

      modalNextFocus.focus();
      setModalNextFocus(null);
    }
  };
  
  const handlePageTimeout = () => {

    if (paymentDone == false)
      window.location.href = '/' + localStorage.getItem('landingTag');
  }; 

  const clickSound = new Audio('/sounds/purchase-ok.mp3');
  clickSound.preload = 'auto';

  const bodyStyle = {

    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };  
  
  return (
    <div style={bodyStyle}>

      <div className="container bg-slate-100 mx-auto p-4 max-w-screen-md">

      <InactivityTimer 
        timeoutDuration={ 5 * 60 * 1000 /* 5 min */ }
        onTimeout={handlePageTimeout}
      />

      {loading && (<Loading />)}
        {!loading && (
          <div>
            <div className="flex justify-center items-center mb-4">
              <EventHeader uuid={eventUUID} />
            </div>
            <div className="mt-20">

            {numInstallments == null || numInstallments <= 1 && <h1 className="text-center text-1xl font-normal mb-1">Valor total da inscrição:</h1>}
            {numInstallments > 1 && <h1 className="text-center text-1xl font-normal mb-1">Valor da parcela de entrada:</h1>}

            <h1 className="text-center text-1xl font-bold mb-2">{utils.formatCurrency(numInstallments <= 1 ? itemCategories.totalPrice : (itemCategories.installmentAmount != null ? itemCategories.installmentAmount : ''))}</h1>

            <h1 className={`text-center text-2xl font-bold mb-4 ${paymentDone ? "text-green-600" : "blink-text text-orange-500"}`}>{paymentDone ? (cartIsFree ? 'ISENTO DE PAGAMENTO' : 'PAGAMENTO EFETUADO') : 'AGUARDANDO PAGAMENTO'}</h1>
            </div>
            <div className="flex justify-center items-center mb-2">
              <QRCodeComponent uuid={cartUUID} token={mdlToken} numInstallments={numInstallments}/>
            </div>

            <h1 className="text-center text-1xl font-normal mb-2">Aponte a câmera do seu celular para o QR Code e faça um Pix no app do seu banco.</h1>

            <div className="flex justify-center items-center mb-4">
              <img src="../assets/pix-bc-logo-0-1024.png" alt="Pix Logo" className="max-w-full h-auto" style={{ maxWidth: '150px' }}/>
            </div>

            <div className="flex justify-center items-center mt-4">
              <CopyToClipboardButton uuid={cartUUID} token={mdlToken} numInstallments={numInstallments}/>
            </div>

            {timeLeft != null && (
              <div className="p-4 text-center">
                <p className="text-s font-normal text-green-700">
                  {"Sessão expira em " + utils.formatTime(timeLeft)}
                </p>
              </div>
            )}

            {modalMessage && (
              <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
            )}                       

          </div>
        )}

        {modalMessage && (
          <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
        )}

      </div>
    </div>
  );
}

export default App;