import React, { useState, useEffect, useRef } from 'react';

import ModalMessage    from './ModalMessage';
import ConfirmModal    from './ConfirmModal-generic';
import Loading         from './Loading';
import InactivityTimer from './components/InactivityTimer';

import * as utils from './Utils';

import './App.css';

const fetchCarts = async (term) => {

  try {

    const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/cart/admin/search/' + term + '&token=' + localStorage.getItem('mdlToken'), {
      method: 'GET',
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    });

    if (!response.ok)
      throw new Error(response.message);

    const data = await response.json();

    return data;
  }
  catch (error) {

    console.error('Error fetching carts: ', error);
    throw error;
  }
};

function App() {

  const [loading, setLoading] = useState(false);
  const [carts,   setCarts  ] = useState({});

  const [thisTerm,    setThisTerm   ] = useState('');
  const [isTermValid, setIsTermValid] = useState(false);

  const [modalMessage,   setModalMessage] = useState(null);
  const [modalCallback,  setModalCalback] = useState(null);

  const [confirmMessage,      setConfirmMessage     ] = useState(null);
  const [confirmLabelOk,      setConfirmLabelOk     ] = useState(null);
  const [confirmLabelCancel,  setConfirmLabelCancel ] = useState(null);
  const [confirmHandleOk,     setConfirmHandleOk    ] = useState(null);
  const [confirmHandleCancel, setConfirmHandleCancel] = useState(null);

  const inputRef = useRef(null);

  const bodyStyle = {

    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };  

  useEffect(() => {

    setTimeout(() => { inputRef.current?.focus();  }, 0);

  }, []);

  const handleModalMessageClose = () => {

    setModalMessage(null);

    if (modalCallback) {

      modalCallback();
      setModalCalback(null);
    }

    setTimeout(() => { inputRef.current?.focus();  }, 0);
  }; 

  const handleChangeTerm = async (value) => {

    //value = value.trim();
    setIsTermValid( value.trim().length >= 3 );
    setThisTerm(value);
  };

  const handleInputKeyDown = (e) => {

    if (e.key === "Enter") {

      e.preventDefault();
      handleSearchButtonClick();
    }
  };  

  const handleOkKeyDown = (e) => {

    if (e.key === "Enter") {

      e.preventDefault();
      handleModalMessageClose();
    }
  };  

  const Logo = () => {

    return (
      <div className="flex justify-center items-center mb-2">
        <img src="../assets/mdl-logo-circle-bg-white.png" alt="Logo" className="max-w-full h-auto" style={{ maxWidth: '100px' }}/>
      </div>
    );
  };

  const handleSearchButtonClick = async () => {

    if (!isTermValid) {

      setModalMessage("O termo de busca não é válido.");
      setTimeout(() => { inputRef.current?.blur();  }, 100);
      return;
    }

    setLoading(true);

    const loadCarts = async (term) => {

      try {

        const data = await fetchCarts(term);

        if (data != null && data.purchases != null) {

          if (data.purchases.length == 0) {

            setModalMessage("Nenhum carrinho encontrado com esta chave de busca.");
            setTimeout(() => { inputRef.current?.blur();  }, 100);
          }

          setCarts(data);

          setLoading(false);
        }
        else {

          throw new Error('Could not retrieve carts information');
        }
      }
      catch (error) {

        console.log("Error: " + error.message);

        setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
        setTimeout(() => { inputRef.current?.blur();  }, 100);

        setModalCalback(() => {
          return () => {
            window.location.reload();
          };        
        });
      }

      setTimeout(() => { inputRef.current?.focus();  }, 0);
    };

    loadCarts(thisTerm);
  }

  const handlePageTimeout = () => {
  };
  
  const handleRemoveCart = async (cart) => {

    let undo = (cart.cartStatus=='Cancelled' || cart.deletedOn.length>0 ? 1 : 0);

    setConfirmMessage((undo >= 1 ? "Você confirma restaurar o carrinho desse comprador?" : "Você confirma remover o carrinho desse comprador?") + "\n\n" + cart.buyer.name.toUpperCase() + "\n\n");
    setConfirmLabelOk(undo >= 1 ? "RESTAURAR" : "REMOVER");
    setConfirmLabelCancel("Não");
    setConfirmHandleOk(() => {
      return async () => {

        setConfirmMessage(null);

        setLoading(true);

        const url = process.env.REACT_APP_API_ENDPOINT + '/cart/admin/' + cart.cartUUID + (undo >= 1 ? '/restore' : '/remove') + '?token=' + localStorage.getItem('mdlToken');
        try {
    
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              Pragma: 'no-cache',
              'Cache-Control': 'no-cache',
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*'
            }
          });
    
          if (!response.ok)
            throw new Error(response.message);
    
          const data = await fetchCarts(thisTerm);
          if (data != null && data.purchases != null) {
    
            setCarts(data);
            setLoading(false);
          }
          else {
    
            window.location.reload();
          }
        }
        catch (error) {
    
          console.error('Error removing cart: ', error);
    
          setLoading(false);
    
          setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
          setModalCalback(() => {
            return () => {
            };        
          });
        }
      };  
    });
    setConfirmHandleCancel(() => {
      return () => {
        setConfirmMessage(null);
      };
    });
  };

  const handleRemoveCartItem = async (cart, cartItemId, undo) => {

    setLoading(true);

    const url = process.env.REACT_APP_API_ENDPOINT + '/cart/admin/' + cart.cartUUID + '/' + cartItemId + (undo >= 1 ? '/restore' : '/remove') + '?token=' + localStorage.getItem('mdlToken');
    try {

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Pragma: 'no-cache',
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });

      if (!response.ok)
        throw new Error(response.message);

      const data = await fetchCarts(thisTerm);
      if (data != null && data.purchases != null) {

        setCarts(data);
        setLoading(false);
      }
      else {

        window.location.reload();
      }
    }
    catch (error) {

      console.error('Error removing cart item: ', error);

      setLoading(false);

      setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
      setModalCalback(() => {
        return () => {
        };        
      });
    }
  }

  const handleRemoveBundle = (cart, bundle) => {

    let undo = bundle.deletedOn.length>0 ? 1 : 0;

    setConfirmMessage((undo >= 1 ? "Você confirma restaurar esse pacote?" : "Você confirma remover esse pacote?") + "\n\n" + bundle.name.toUpperCase() + "\n\n");
    setConfirmLabelOk(undo >= 1 ? "RESTAURAR" : "REMOVER");
    setConfirmLabelCancel("Não");
    setConfirmHandleOk(() => {
      return () => {
        setConfirmMessage(null);
        handleRemoveCartItem(cart, bundle.cartItemId, undo);
      };  
    });
    setConfirmHandleCancel(() => {
      return () => {
        setConfirmMessage(null);
      };
    });
  };

  const handleRemoveRodeoCategory = (cart, rodeoCategory) => {

    let undo = rodeoCategory.deletedOn.length>0 ? 1 : 0;

    setConfirmMessage((undo >= 1 ? "Você confirma restaurar essa categoria?" : "Você confirma remover essa categoria?") + "\n\n" + rodeoCategory.name.toUpperCase() + "\n\n");
    setConfirmLabelOk(undo >= 1 ? "RESTAURAR" : "REMOVER");
    setConfirmLabelCancel("Não");
    setConfirmHandleOk(() => {
      return () => {
        setConfirmMessage(null);
        handleRemoveCartItem(cart, rodeoCategory.cartItemId, undo);
      };  
    });
    setConfirmHandleCancel(() => {
      return () => {
        setConfirmMessage(null);
      };
    });
  };

  const handlePrintReceipt = async (cartUUID) => {

    setLoading(true);

    const printWindow = window.open('', '_blank');

    try {

      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'cart/' + cartUUID + '/receipt/html', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: localStorage.getItem('mdlToken'),
        }),
      });

      if (!response.ok)
        throw new Error(response.message);

      const result = await response.json();
      if (!result || result.status != 'OK')
        throw new Error("Result: " + result.msg);

      printWindow.document.write(result.receiptHTML);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      setTimeout(() => { printWindow.close();  }, 250);
      //printWindow.onafterprint = () => { printWindow.close(); };

      //printWindow.document.write(result.receiptHTML);
      //printWindow.document.close();
      //printWindow.focus();
      //printWindow.print();
      //setTimeout(() => { printWindow.close();  }, 250);

      setLoading(false);
    }
    catch (error) {

      console.error('Error printing receipt: ', error);
      printWindow.close(); 

      setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
      setModalCalback(() => {
        return () => {
          setLoading(false);
        };
      });
    }
  }

  const formatCartStatus = (cart) => {

    if (cart.cartStatus == 'Cancelled' || cart.deletedOn.length>0)
      return 'Cancelado';
    else if (cart.cartStatus == 'Registered')
      return 'Registrado';
    else if (cart.cartStatus == 'Paid')
      return 'Pago';
    else
      return cart.cartStatus;
  }

  const formatWhatsappMsg = (userName, cart) => {

    return "Olá! Aqui é " + userName + " e gostaria de falar sobre a sua inscrição no evento " + cart.eventName.toUpperCase() + ". Tudo bem?";
  }

  return (
    <div style={bodyStyle}>
        <InactivityTimer 
          timeoutDuration={ 1 * 60 * 1000 /* 1 min */ }
          onTimeout={handlePageTimeout}
        />
      <div className="container bg-slate-100 mx-auto p-4 max-w-screen-md">
        {loading && (<Loading />)}
        {!loading && (
          <div>
            <Logo />
            
            <div className="p-2">
              <label className="block mb-2">Digite código do carrinho, txid, e2eid, CPF, nome, email ou telefone do comprador:</label>
              <div className="relative w-90">
                <input 
                  ref={inputRef}
                  id="termField"
                  type="text"
                  value={thisTerm}
                  name="term"
                  maxLength="100"
                  placeholder="Digite a chave de busca"
                  className="border-2 rounded w-full p-1"
                  onChange={(e) => handleChangeTerm(e.target.value)}
                  onKeyDown={handleInputKeyDown}
                  //inputMode="numeric"
                  //pattern="[0-9]*"
                  required
                />
                {thisTerm && (
                  <button
                    onClick={() => { setThisTerm(""); setIsTermValid(false); setCarts({}); }}
                    className="absolute inset-y-0 right-2 flex items-center text-gray-500 hover:text-gray-700"
                    >
                    ✕
                  </button>
                )}              
              </div>
            </div>

            <div className="flex justify-center items-center m-2">
              <button type="submit" className={`bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold ${isTermValid ? '' : 'opacity-50'}`}
                onClick={handleSearchButtonClick} style={{ minWidth: '150px' }}>
                Buscar Carrinhos
              </button>
            </div>

            {carts.purchases && carts.purchases.length>0&&(
              <div>
                <div className="mt-6">
                  <h1 className="text-center text-1xl font-bold mb-6">
                    {carts.purchases.length != 1 ? carts.purchases.length + " carrinhos encontrados:" : "1 carrinho encontrado:"}
                  </h1>
                </div>
              </div>
            )}

            <ul className="grid grid-cols-1 -mx-2">
              {carts.purchases && carts.purchases.map((cart, index1) => (
                <li 
                  className={"flex flex-col justify-between items-left mb-6 bg-white p-4 rounded-lg shadow-md " + (cart.cartStatus=='Cancelled' || cart.deletedOn.length>0 ? "border-red-500" : "border-gray-300") + " border-2"}
                >
                  {
                    <div>
                      <div>
                        <p className="text-lg font-semibold mb-0">{cart.eventName.toUpperCase()}</p>
                      </div>
                      <ul className="mt-1">
                        <li className="border mt-1 p-4 rounded bg-gray-50">
                          <div>
                            <p className="text-l mt-1 font-semibold">{"Status: " + formatCartStatus(cart)}</p>
                            <p className="text-l mt-1">{"Código: " + cart.cartCode}</p>
                            <p className="text-l mt-1">{"Criado em: " + utils.formatDate(cart.createdOn, true)}</p>
                            <p className="text-l mt-1">{"Atualizado em: " + utils.formatDate(cart.updatedOn, true)}</p>
                            {cart.deletedOn.length>0 && (<p className="text-l mt-1">{"Removido em: " + utils.formatDate(cart.deletedOn, true)}</p>)}
                          </div>

                          {(cart.cartStatus!='Cancelled' && cart.deletedOn.length==0)&&(
                            <div className="flex justify-center items-center mt-2 m-2">
                              <button
                                className="bg-green-500 text-white px-4 py-2 rounded text-l font-bold"
                                onClick={() => handlePrintReceipt(cart.cartUUID)}
                                style={{ minWidth: '250px' }}>
                                Gerar comprovante
                              </button>
                            </div>
                          )}

                          <div className="flex justify-center mt-2">
                            <button 
                              className={`px-4 py-2 rounded font-bold ` + (cart.cartStatus=='Cancelled' || cart.deletedOn.length>0 ? `bg-blue-400` : `bg-orange-400`) + ` text-white border border-gray-300 opacity-100 cursor-not-allowed'}`}
                              onClick={() => handleRemoveCart(cart, cart.cartStatus=='Cancelled' || cart.deletedOn.length>0 ? 1 : 0)} style={{ minWidth: '250px' }}>
                              {cart.cartStatus=='Cancelled' || cart.deletedOn.length>0 ? "Restaurar carrinho" : "Remover carrinho"}
                            </button>
                          </div>

                        </li>
                      </ul>
                    </div>
                  }

                  {
                    <div>
                      <div>
                        <p className="text-lg mt-4 mb-0">Comprador:</p>
                      </div>
                      <ul className="mt-1">
                        <li className="border mt-1 p-4 rounded bg-blue-50">
                          <div>
                            <p className="text-l mt-1 font-semibold">{cart.buyer.name.toUpperCase()}</p>
                            <p className="text-l mt-1">{"CPF: " + cart.buyer.cpf}</p>
                            <p className="text-l mt-1">{"Tel.: " + cart.buyer.mobile}</p>
                            <p className="text-l mt-1 mb-2">{"E-mail: " + cart.buyer.email}</p>
                            <a style={{color:"green"}} href={"https://wa.me/55" + cart.buyer.mobile + "?text=" + formatWhatsappMsg(carts.userNickname, cart)}>
                              <img align="center" style={{height:"25px",padding:"0px 0px",display:"inline"}} src="assets/whatsapp-icon-green.png" />&nbsp;Contatar via Whatsapp
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  }

                  {cart.items.bundles.length>0&&(
                    <div>
                      <div>
                        <p className="text-lg mt-4 mb-0">Pacotes:</p>
                      </div>
                      <ul className="mt-1">
                        {cart.items.bundles.map((bundle, index2) => (
                          <li className={"border mt-1 p-4 rounded " + (bundle.deletedOn.length>0 ? "border-red-500" : "border-gray-200")}>
                            <div>
                              <p className="text-l mt-1 font-semibold">{bundle.name.toUpperCase()}</p>
                              <div className="mt-1">
                                <p className="text-l mt-1">{"Valor: " + utils.formatCurrency(bundle.chargedValue)}</p>
                                {bundle.deletedOn.length>0 && (<p className="text-l mt-1">{"Removido em: " + utils.formatDate(bundle.deletedOn, true)}</p>)}
                              </div>
                            </div>

                            <div className="flex justify-center mt-2">
                              <button 
                                className={`px-4 py-2 rounded font-bold ` + (bundle.deletedOn.length>0 ? `bg-blue-400` : `bg-orange-400`) + ` text-white border border-gray-300 opacity-100 cursor-not-allowed'}`}
                                onClick={() => handleRemoveBundle(cart, bundle, bundle.deletedOn.length>0 ? 1 : 0)} style={{ minWidth: '150px' }}>
                                {bundle.deletedOn.length>0 ? "Restaurar pacote" : "Remover pacote"}
                              </button>
                            </div>

                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {cart.items.rodeoCategories.length>0&&(
                    <div>
                      <div>
                        <p className="text-lg mt-4 mb-0">Categorias:</p>
                      </div>
                      <ul className="mt-1">
                        {cart.items.rodeoCategories.map((rodeoCategory, index2) => (
                          <li className={"border mt-1 p-4 rounded " + (rodeoCategory.deletedOn.length>0 ? "border-red-500" : "border-gray-200")}>
                            <div>
                              <p className="text-l mt-1 font-semibold">{rodeoCategory.name.toUpperCase()}</p>
                              <div className="mt-1">
                                <p className="text-l mt-1">{"Valor: " + utils.formatCurrency(rodeoCategory.chargedValue)}</p>
                                <p className="text-l mt-1">{"Número: " + (rodeoCategory.badge?rodeoCategory.badge:'N/A')}</p>
                                {rodeoCategory.strength? <p className="text-l mt-1">{"Força: " + rodeoCategory.strength}</p> : ''}
                                {rodeoCategory.deletedOn.length>0 && (<p className="text-l mt-1">{"Removido em: " + utils.formatDate(rodeoCategory.deletedOn, true)}</p>)}
                              </div>
                            </div>

                            <div className="flex justify-center mt-2">
                              <button 
                                className={`px-4 py-2 rounded font-bold ` + (rodeoCategory.deletedOn.length>0 ? `bg-blue-400` : `bg-orange-400`) + ` text-white border border-gray-300 opacity-100 cursor-not-allowed'}`}
                                onClick={() => handleRemoveRodeoCategory(cart, rodeoCategory, rodeoCategory.deletedOn.length>0 ? 1 : 0)} style={{ minWidth: '150px' }}>
                                {rodeoCategory.deletedOn.length>0 ? "Restaurar categoria" : "Remover categoria"}
                              </button>
                            </div>

                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {cart.payment.pix.length>0&&(
                    <div>
                      <div>
                        <p className="text-lg mt-4 mb-0">Pagamentos:</p>
                      </div>
                      <ul className="mt-1">
                        {cart.payment.pix.map((pix, index2) => (
                          <li className="border mt-1 p-4 rounded bg-green-50">
                            <div>
                              <p className="text-l mt-1 font-semibold">{"Valor: " + utils.formatCurrency(pix.chargedValue)}</p>
                              <p className="text-l mt-1 font-semibold">{"Data: " + utils.formatDate(pix.paidOn, true)}</p>
                              <p className="text-xs mt-2">{"Txid: " + pix.txid}</p>
                              <p className="text-xs mt-2">{"E2eid: " + pix.endToEndId}</p>

                              {pix.returns.length>0&&(
                                <div>
                                  <div>
                                    <p className="text-lg mt-4 mb-0">Devoluções:</p>
                                  </div>
                                  <ul className="mt-1">
                                    {pix.returns.map((ret, index3) => (
                                      <li className="border mt-1 p-4 rounded bg-green-100">
                                        <div>
                                          <p className="text-l mt-1 font-semibold">{"Valor: " + utils.formatCurrency(ret.returnedValue)}</p>
                                          <p className="text-l mt-1 font-semibold">{"Data: " + utils.formatDate(ret.returnedOn, true)}</p>
                                          <p className="text-xs mt-2">{"Desc.: " + ret.returnDesc}</p>
                                          <p className="text-xs mt-2">{"Status: " + ret.returnStatus}</p>
                                          <p className="text-xs mt-2">{"Id: " + ret.id}</p>
                                          <p className="text-xs mt-2">{"RtrId: " + ret.rtrId}</p>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}

                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                </li>
              ))}
            </ul>

            <div className="mb-16">
            </div>

            {modalMessage && (
              <ModalMessage message={modalMessage} onClose={handleModalMessageClose}/>
            )}                       

          </div>
        )}
        {modalMessage && (
          <ModalMessage message={modalMessage} onClose={handleModalMessageClose}/>
        )}
        {confirmMessage && (
          <ConfirmModal message={confirmMessage} okLabel={confirmLabelOk} cancelLabel={confirmLabelCancel} onOk={confirmHandleOk} onCancel={confirmHandleCancel} />
        )}
      </div>
    </div>
  );
}

export default App;